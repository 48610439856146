import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    info: {}
};

export const userInfoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        resetUserInfo: (state) => {
            state.info = [];
        },
        saveUserInfo: (state, { payload }) => {
            if (payload) {
                state.info = payload;
            }
        }
    }
});

// Action creators are generated for each case reducer function
export const { resetUserInfo, saveUserInfo } = userInfoSlice.actions;

export default userInfoSlice.reducer;

