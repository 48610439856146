import enUsLang from "./entries/en-US";
import esEsLang from "./entries/es-ES";

const AppLocale = [
    {
        id: "enUs",
        lang: enUsLang
    },
    {
        id: "esEs",
        lang: esEsLang
    }
];

export default AppLocale;
