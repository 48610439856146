import * as lodashIsEmpty from "lodash.isempty";
import get from "lodash.get";
import { unAuthenticatedRoutes, authenticatedRoutes, permissionsConstants } from "../routes/constant";
import notificationService from "./notification.service";
import moment from "moment";
import AuthService from "./auth.service";
import dateTimeService from "./date-time.service";
import { ADMIN, ADMIN_SLUG } from "../constants/constant";
import { globalDateFormat, LOCAL_STORAGE_DATA, VIEW_DOCUMENT_ACTIONS } from "./constants/constant";
import { dateFields } from "../containers/ContactProfile/constant";
import { isNumber, zip } from "lodash";

export const phoneMask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/];
export const ssnMask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

function replaceNullWithPlaceholder(value, placeHolder = "--") {
    if (!value) {
        return placeHolder;
    }
    return value;
}

function getValue(...param) {
    return get(...param);
}

function redirectToLogin(loginUrl = utilService.loginUrl) {
    let returnUrl = encodeURIComponent(window.location.pathname.replace(/[//]+/, "") + window.location.search);
    window.location.href = loginUrl + "?returnUrl=" + returnUrl;
    // utilService.redirectTo(loginUrl + "?returnUrl=" + returnUrl);
}

function redirectTo(url) {
    window.location.href = utilService.baseUrl + url;
}

function redirectToReturnUrl() {
    utilService.redirectTo(
        utilService.getUrlParameterByName("returnUrl")
            ? "/" + utilService.getUrlParameterByName("returnUrl")
            : utilService.defaultUrl
    );
}

function getUrlParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regexS = `[\\?&]${name}=([^&#]*)`;
    const regex = new RegExp(regexS);
    const results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function isEmpty(value) {
    return lodashIsEmpty(value);
}

function numberFormat(number, n, x, s, c) {
    const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? "\\D" : "$"})`;
    const num = number.toFixed(Math.max(0, ~~n));

    return (c ? num.replace(".", c) : num).replace(new RegExp(re, "g"), `$&${s || ","}`);
}

function toNumber(input, decimal = 2) {
    if (input && !isNaN(input)) {
        return numberFormat(input, decimal, 3);
    }
    return input;
}

function getQueryParams(query, param) {
    let params = new URLSearchParams(query);
    return params.get(param);
}

function getUrlParam(urlParam, search) {
    return new URLSearchParams(urlParam).get(search);
}

function capitalizeFirstLetter(value) {
    if (!value) return null;

    return value.charAt(0).toUpperCase() + value.slice(1);
}
function capitalizeSentence(value) {
    if (!value) return null;
    let temp = value?.split("-");
    let cap = temp?.map((item) => item[0].toUpperCase() + item.slice(1)?.toLowerCase())?.join("-");
    return cap;
}

function apiPrintError(data) {
    if (data) {
        const { message: singleMessage = false } = data;

        if (singleMessage) notificationService.warning(singleMessage);
    }
}

async function downloadFileFromUrl(url, fileName) {
    if (!url) return null;
    // const aTag = document.createElement("a");
    // aTag.href = url;
    // // aTag.download = fileName.toUpperCase().replace(".pdf", "");
    // aTag.download = "";
    // aTag.click();
    // document.body.appendChild(aTag);
    // document.body.removeChild(aTag);

    await fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = fileName ?? "";
            link.click();
        })
        .catch(console.error);

    // const ancorElement = document.createElement("a");
    // ancorElement.setAttribute("href", url);
    // ancorElement.setAttribute("target", "_blank");

    // ancorElement.style.display = "none";
    // document.body.appendChild(ancorElement);

    // ancorElement.click();
}

async function viewDownloadFileFromUrl(url, fileName) {
    if (!url) return null;
    // const aTag = document.createElement("a");
    // aTag.href = url;
    // // aTag.download = fileName.toUpperCase().replace(".pdf", "");
    // aTag.download = "";
    // aTag.click();
    // document.body.appendChild(aTag);
    // document.body.removeChild(aTag);

    const ancorElement = document.createElement("a");
    ancorElement.setAttribute("href", url);
    ancorElement.setAttribute("target", "_blank");

    ancorElement.style.display = "none";
    document.body.appendChild(ancorElement);

    ancorElement.click();
}

function convertDateToOurFormat(date) {
    if (!date) {
        return "--";
    }
    const d = new Date(date);
    const customMoment = moment(d);
    // return `${customMoment.format("l")} ${customMoment.format("LT")}`;
    return `${customMoment.format(globalDateFormat)}`;
}
function convertDateToFormat(date, format) {
    const d = new Date(date);
    const customMoment = moment(d);
    return `${customMoment.format(format)}`;
}

// replace object key with value on matching url sub string
function createDynamicUrl(dynamicUrl, object) {
    for (const key in object) {
        dynamicUrl = dynamicUrl.replace(`{${key}}`, object[key]);
    }
    return dynamicUrl;
}
function arrayToUrl(baseURL, array = []) {
    if (Array.isArray(array)) {
        for (let index = 0; index < array.length; index++) {
            if (index === 0) {
                if (index === array.length - 1) {
                    return (baseURL += `?roles=${array[index]}`);
                }
                baseURL += `?roles=${array[index]}&`;
            } else if (index === array.length - 1) {
                baseURL += `roles=${array[index]}`;
            } else {
                baseURL += `roles=${array[index]}&`;
            }
        }
    }
    return baseURL;
}
function stringLimit(str = "", limit = 18, replaceWith = "..") {
    if (str?.length > 18) {
        return `${str.substring(0, limit)} ${replaceWith}`;
    }
    return str;
}
function disabledDate(current) {
    return current && current <= moment().startOf("day");
}
function disabledDateBefore(current) {
    return current && current > moment().startOf("day");
}
function priceFormat(value) {
    return value?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "-";
}

//String Cases

/**
 * Replaces all accented chars with regular ones
 */
function replaceAccents(str) {
    // verifies if the String has accents and replace them
    if (str?.search(/[\xC0-\xFF]/g) > -1) {
        str = str
            .replace(/[\xC0-\xC5]/g, "A")
            .replace(/[\xC6]/g, "AE")
            .replace(/[\xC7]/g, "C")
            .replace(/[\xC8-\xCB]/g, "E")
            .replace(/[\xCC-\xCF]/g, "I")
            .replace(/[\xD0]/g, "D")
            .replace(/[\xD1]/g, "N")
            .replace(/[\xD2-\xD6\xD8]/g, "O")
            .replace(/[\xD9-\xDC]/g, "U")
            .replace(/[\xDD]/g, "Y")
            .replace(/[\xDE]/g, "P")
            .replace(/[\xE0-\xE5]/g, "a")
            .replace(/[\xE6]/g, "ae")
            .replace(/[\xE7]/g, "c")
            .replace(/[\xE8-\xEB]/g, "e")
            .replace(/[\xEC-\xEF]/g, "i")
            .replace(/[\xF1]/g, "n")
            .replace(/[\xF2-\xF6\xF8]/g, "o")
            .replace(/[\xF9-\xFC]/g, "u")
            .replace(/[\xFE]/g, "p")
            .replace(/[\xFD\xFF]/g, "y");
    }

    return str;
}

/**
 * Remove non-word chars.
 */
function removeNonWord(str) {
    return str.replace(/[^0-9a-zA-Z\xC0-\xFF \-]/g, "");
}

/**
 * Convert string to camelCase text.
 */
function camelCase(str) {
    if (typeof str === "string" || str instanceof String) {
        str = replaceAccents(str);
        str = removeNonWord(str)
            .replace(/\-/g, " ") //convert all hyphens to spaces
            .replace(/\s[a-z]/g, upperCase) //convert first char of each word to UPPERCASE
            .replace(/\s+/g, "") //remove spaces
            .replace(/^[A-Z]/g, lowerCase); //convert first char to lowercase
        return str;
    } else {
        return str;
    }
}

/**
 * camelCase + UPPERCASE first char
 */
function pascalCase(str) {
    if (typeof str === "string" || str instanceof String) {
        return camelCase(str).replace(/^[a-z]/, upperCase);
    } else {
        return str;
    }
}

/**
 * "Safer" String.toUpperCase()
 */
function upperCase(str) {
    return str.toUpperCase();
}

/**
 * "Safer" String.toLowerCase()
 */
function lowerCase(str) {
    return str?.toLowerCase();
}

function checkIfUserIsAdmin() {
    const userInfo = AuthService.getUserInfo();
    if (userInfo?.user?.role?.name == ADMIN || userInfo?.user?.role?.slug === ADMIN_SLUG) {
        return true;
    }

    return false;
}
function checkPermissionOfUser(permissionName = false) {
    if (permissionName) {
        const userInfo = AuthService.getUserInfo();

        if (userInfo?.user?.role?.name == ADMIN) {
            return true;
        }

        const permissionNames = userInfo?.user?.userPermissions?.map((singlePermission) => {
            return singlePermission?.permissions?.attributes?.slug;
        });

        // const pascalCasePermissionName = utilService.pascalCase(permissionName);

        if (permissionNames?.includes(permissionName)) {
            return true;
        }
    }

    return false;
}

function checkPermissionQueryOfRoleIds(permissionName = false) {
    const userInfo = AuthService.getUserInfo();

    if (userInfo?.user?.role?.name == ADMIN) {
        return true;
    }

    const permissionObject = userInfo?.user?.userPermissions?.find((singlePermission) => {
        // return singlePermission?.permissions?.attributes?.slug === permissionsConstants.UserListOnlyRelationUsers;
        return (
            singlePermission?.permissions?.attributes?.slug === permissionsConstants.UserListOnlyRelationUsers &&
            singlePermission?.rolesPermissionQuery?.length > 0
        );
    });

    if (permissionObject) {
        const roleIds = permissionObject?.rolesPermissionQuery
            ?.find((singlePermQuery) => singlePermQuery.name === "role_id")
            ?.value?.map((singleRolePermissionQuery) => {
                return singleRolePermissionQuery;
            });

        return roleIds;
    }

    return false;
}
const phoneMasking = (e, form) => {
    if (form) {
        let { value: inputValue, name } = e.target;
        if (inputValue || inputValue === "" || inputValue === "-") {
            if (inputValue?.length > 14) {
                return false;
            }
            inputValue = inputValue.replace(/\D/g, "");
            inputValue = inputValue.replace(/^(\d{1})(\d)/g, "$1-$2");
            inputValue = inputValue.replace(/(\d{3})(\d{3})(\d{4})$/g, "$1-$2-$3");
            return form.setFieldsValue({ [name]: inputValue });
        }
    } else {
        let inputValue = e;
        if (inputValue || inputValue === "" || inputValue === "-") {
            inputValue = inputValue.replace(/\D/g, "");
            inputValue = inputValue.replace(/^(\d{1})(\d)/g, "$1-$2");
            inputValue = inputValue.replace(/(\d{3})(\d{3})(\d{4})$/g, "$1-$2-$3");
            return inputValue;
        }
    }
    return false;
};
const getParamFromUrl = (url, paramName) => {
    let urlWithParams = new URL(url);
    return urlWithParams.searchParams.get(paramName);
};

const replaceAndToHyphen = (data = "") => {
    if (!data) {
        return null;
    }
    const returnedData = data.replace("&", "-");
    return returnedData;
};

const generateUrlWithoutParamsFormsForUser = (url) => {
    const returnUrl = addClientApiKey(url);
    return returnUrl;
};

const encodedToBase64 = (data) => btoa(JSON.stringify(data));
const decodeToBase64 = (data) => JSON.parse(atob(data));

const removeNumberFromString = (string = "") => {
    const converetedString = string.replace(/[0-9]/g, "");
    return converetedString;
};

function disabledPastDate(current) {
    return current && current <= moment().startOf("day");
}

function openDocuSignPopupCondtionalyUrl(record) {
    let url = "";

    if (record?.attributes?.editUrl) {
        url = record?.attributes?.editUrl;
        localStorage.setItem(LOCAL_STORAGE_DATA.VIEW_DOCUMENT_ACTION, VIEW_DOCUMENT_ACTIONS.EDIT_URL);
    } else if (record?.attributes?.document?.url) {
        url = record?.attributes?.document?.url;
        localStorage.setItem(LOCAL_STORAGE_DATA.VIEW_DOCUMENT_ACTION, VIEW_DOCUMENT_ACTIONS.CREATE_URL);
    }

    const returnUrl = addClientApiKey(url);
    return debugModeOfSignature(returnUrl);
}

const debugModeOfSignature = (returnUrl) => {
    // return returnUrl.replace("https://dev-signature.visionze.com/", "http://localhost:3000/");
    // return returnUrl.replace("https://signature.visionze.com/", "http://localhost:3000/");
    // return returnUrl.replace("https://abilitiesinc-signature.visionze.com/", "http://localhost:3000/");
    return returnUrl;
};

function phoneNumberMasking(number) {
    if (!number) {
        return number;
    }
    const tempNumber = number.toString();
    const num = `${tempNumber?.substring(0, 3)}-${tempNumber?.substring(3, 6)}-${tempNumber?.substring(
        6,
        tempNumber.length
    )}`;
    return num;
}

function urlParameters() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params;
}

function notificationMaxCount(value) {
    let tempValue = value;
    if (!isNumber(value)) {
        tempValue = 0;
    }

    if (tempValue > 100) {
        return "99+";
    }

    return tempValue;
}

const generateUrlWithParamsForms = (url, contact, quaterlyForm = false, firstNameParam, lastNameParam) => {
    const userRow = contact?.attributes;
    return commonGenerateQueryStringsAndVariables(url, userRow, quaterlyForm, firstNameParam, lastNameParam);
};

const generateUrlWithParamsFormsForUser = (url, userRow) => {
    return commonGenerateQueryStringsAndVariables(url, userRow);
};

const commonGenerateQueryStringsAndVariables = (
    url,
    userRow,
    quaterlyForm = false,
    firstNameParam = "",
    lastNameParam = ""
) => {
    let firstName = replaceAndToHyphen(userRow?.firstName);
    let lastName = replaceAndToHyphen(userRow?.lastName);
    const email = replaceAndToHyphen(userRow?.email);

    if (quaterlyForm) {
        firstName = firstNameParam;
        firstName = lastNameParam;
    }

    let fullAddressParam =
        replaceAndToHyphen(userRow?.address?.mailingAddress) || replaceAndToHyphen(userRow?.address?.physicalAddress);

    const streedAddress = fullAddressParam;

    let isMailingAddress = false;

    if (Boolean(userRow?.address?.mailingAddress)) {
        isMailingAddress = true;
    }

    let city = userRow?.address?.physicalCity;
    let state = userRow?.address?.physicalState;
    let zipcode = userRow?.address?.physicalZip;

    if (isMailingAddress) {
        city = userRow?.address?.mailingCity;
        state = userRow?.address?.mailingState;
        zipcode = userRow?.address?.mailingZip;
    }

    if (city) {
        fullAddressParam += ` ${city},`;
    }
    if (state) {
        fullAddressParam += ` ${state}`;
    }
    if (zipcode) {
        fullAddressParam += ` ${zipcode}`;
    }

    const phoneParam =
        replaceAndToHyphen(userRow?.address?.mailingPhone) || replaceAndToHyphen(userRow?.address?.physicalPhone);

    const queryStrings = [];

    const cloneDetail = { ...userRow?.details };
    Object.keys(cloneDetail).map((singleKey) => {
        const queryValue = cloneDetail[singleKey];

        if (queryValue) {
            let queryValueExact = "";

            if (dateFields.some((item) => item === singleKey)) {
                queryValueExact = dateTimeService.formatDate(queryValue);
            } else {
                queryValueExact = queryValue;
            }

            // if (singleKey === "organizationType") {
            //     queryValueExact = null;
            //     if (Array.isArray(queryValue)) {
            //         if (queryValue?.length > 0) {
            //             queryValue?.forEach((item) => {
            //                 queryValue += `${item} ,`;
            //             });
            //         }
            //     }
            // }

            queryStrings.push({
                queryName: singleKey,
                value: queryValueExact
            });
        }
    });

    const relationsArray = userRow?.relations?.filter((singleRelation) => {
        return singleRelation?.relation?.role?.slug === "client-service-coordinator";
    });

    const relationNames = relationsArray?.map((singleRelation) => {
        return singleRelation?.relation?.name;
    });

    if (relationNames?.length > 0) {
        queryStrings.push({
            queryName: "coordinatorName",
            value: relationNames
        });
    }

    const liddaFirstName = userRow?.details?.liddaFirstName;
    const liddaLastName = userRow?.details?.liddaLastName;

    if (liddaFirstName && liddaLastName) {
        queryStrings.push({
            queryName: "liddaFullName",
            value: `${liddaFirstName || ""} ${liddaLastName || ""}`
        });
    }

    if (lastName && firstName) {
        queryStrings.push({
            queryName: "name",
            value: `${lastName || ""} ${firstName || ""}`
        });
    }

    queryStrings.push({
        queryName: "email",
        value: email || ""
    });

    if (fullAddressParam) {
        queryStrings.push({
            queryName: "fullAddress",
            value: fullAddressParam || ""
        });
    }

    if (streedAddress) {
        queryStrings.push({
            queryName: "streetAddress",
            value: streedAddress || ""
        });
    }

    if (phoneParam) {
        queryStrings.push({
            queryName: "phone",
            value: phoneParam
        });
    }

    if (firstName) {
        queryStrings.push({
            queryName: "firstName",
            value: firstName || ""
        });
    }

    if (lastName) {
        queryStrings.push({
            queryName: "lastName",
            value: lastName || ""
        });
    }

    //first name / last name
    if (lastName && firstName) {
        queryStrings.push({
            queryName: "firstNameLastName",
            value: `${firstName || ""} ${lastName || ""}`
        });
    }

    //last name / first name
    if (lastName && firstName) {
        queryStrings.push({
            queryName: "lastNameFirstName",
            value: `${lastName || ""} ${firstName || ""}`
        });
    }
    //effective date

    queryStrings.push({
        queryName: "effectiveDate",
        value: moment().format("MM DD YYYY")
    });

    const userDiagnosisArray = userRow?.userDiagnosis || [];
    const diagnosisCodes = userDiagnosisArray?.map((singleDiagnosis) => {
        return singleDiagnosis?.code;
    });
    const diagnosisNames = userDiagnosisArray?.map((singleDiagnosis) => {
        return singleDiagnosis?.name;
    });

    if (diagnosisCodes?.length > 0) {
        queryStrings.push({
            queryName: "diagnosisCodes",
            value: diagnosisCodes
        });
    }

    if (diagnosisNames?.length > 0) {
        queryStrings.push({
            queryName: "diagnosisNames",
            value: diagnosisNames
        });
    }

    //signer user first name & signer user last name
    const userInfo = AuthService.getUserInfo()?.user;

    const signerFirstName = pascalCase(userInfo?.firstName);
    const signerLastName = pascalCase(userInfo?.lastName);

    if (signerFirstName || signerLastName) {
        queryStrings.push({
            queryName: "signerName",
            value: `${signerFirstName?.toUpperCase() || ""} ${signerLastName?.toUpperCase() || ""}`
        });
    }

    const signerEmail = replaceAndToHyphen(userInfo?.email);

    queryStrings.push({
        queryName: "signerEmail",
        value: signerEmail || ""
    });

    const age = calculateAge(cloneDetail?.dateOfBirth);
    queryStrings.push({
        queryName: "age",
        value: age
    });

    console.log("queryStrings: ", queryStrings);
    const encodeDataToBase64 = encodedToBase64(queryStrings);
    let urlParams = `${url}&data=${encodeDataToBase64}`;

    const returnUrl = addClientApiKey(urlParams);
    return debugModeOfSignature(returnUrl);
};

function addSignerNameAndEmail(url) {
    const userInfo = AuthService.getUserInfo()?.user;
    const signerFirstName = pascalCase(userInfo?.firstName);
    const signerLastName = pascalCase(userInfo?.lastName);
    const email = replaceAndToHyphen(userInfo?.email);

    const queryStrings = [];

    if (signerFirstName || signerLastName) {
        queryStrings.push({
            queryName: "signerName",
            value: `${signerFirstName?.toUpperCase() || ""} ${signerLastName?.toUpperCase() || ""}`
        });
    }

    queryStrings.push({
        queryName: "email",
        value: email || ""
    });

    queryStrings.push({
        queryName: "signerEmail",
        value: email || ""
    });

    const encodeDataToBase64 = encodedToBase64(queryStrings);
    let urlParams = `${url}&data=${encodeDataToBase64}`;
    const returnUrl = addClientApiKey(urlParams);
    return returnUrl;
}

function calculateAge(date) {
    const birthDate = moment(date);
    const currentDate = moment();
    const age = currentDate.diff(birthDate, "years");
    return age;
}

const addCustomQueryStrings = (url, queryStrings) => {
    const encodeDataToBase64 = encodedToBase64(queryStrings);
    let urlParams = `${url}&data=${encodeDataToBase64}`;

    const returnUrl = addClientApiKey(urlParams);
    return debugModeOfSignature(returnUrl);
};

const addClientApiKey = (url) => {
    const REACT_APP_VISIONIZE_API_KEY = process.env.REACT_APP_VISIONIZE_API_KEY;
    if (REACT_APP_VISIONIZE_API_KEY) {
        url += `&client_api_key=${REACT_APP_VISIONIZE_API_KEY}`;
    }
    return url;
};

const utilService = {
    phoneNumberMasking,
    loginUrl: unAuthenticatedRoutes.LOGIN,
    defaultUrl: authenticatedRoutes.DASHBOARD,
    baseUrl: process.env.REACT_APP_BASE_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    userInactivityTimeLimit: process.env.REACT_APP_USER_INACTIVITY_TIME_LIMIT_IN_MINUTES,
    openDocuSignPopupCondtionalyUrl,
    toNumber,
    getValue,
    redirectToLogin,
    redirectTo,
    redirectToReturnUrl,
    getUrlParameterByName,
    isEmpty,
    getQueryParams,
    getUrlParam,
    replaceNullWithPlaceholder,
    capitalizeFirstLetter,
    apiPrintError,
    downloadFileFromUrl,
    convertDateToOurFormat,
    createDynamicUrl,
    convertDateToFormat,
    stringLimit,
    disabledDate,
    disabledDateBefore,
    arrayToUrl,
    replaceAccents,
    removeNonWord,
    camelCase,
    pascalCase,
    upperCase,
    lowerCase,
    checkPermissionOfUser,
    getParamFromUrl,
    checkIfUserIsAdmin,
    removeNumberFromString,
    generateUrlWithParamsForms,
    generateUrlWithParamsFormsForUser,
    generateUrlWithoutParamsFormsForUser,
    disabledPastDate,
    capitalizeSentence,
    encodedToBase64,
    decodeToBase64,
    phoneMask,
    ssnMask,
    phoneMasking,
    checkPermissionQueryOfRoleIds,
    viewDownloadFileFromUrl,
    addClientApiKey,
    urlParameters,
    notificationMaxCount,
    addCustomQueryStrings,
    calculateAge,
    addSignerNameAndEmail,
    priceFormat
};

export default utilService;

