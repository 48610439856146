import antdEnUs from "antd/lib/locale-provider/en_US";

const enUsLanguage = {
    name: "English",
    antdLocale: antdEnUs,
    i18nLocale: "en-US",
    momentLocale: "en-us",
    direction: "ltr",
    icon: "us"
};
export default enUsLanguage;
