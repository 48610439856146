const paginationConstant = {
    PAGE_SIZE: 10
};

const pageProperties = {
    WIDTH: 1024,
    OVERFLOW_Y: 250
};

export const STORAGE_CONST = {
    USER_INFO: "TOKEN",
    FORGOT_EMAIL: "FORGOT-EMAIL",
    CONTACT_PROFILE_MODAL_VISIBLE: "CONTACT_PROFILE_MODAL_VISIBLE"
};
export const ADMIN = "Admin";
export const ADMIN_SLUG = "sub-admin";
export const SINGLE_USER_COST = 16;

export { paginationConstant, pageProperties };
