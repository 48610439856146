import React from "react";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import AuthService from "../util/auth.service";
import AppLocale from "../languageProvider";

const AuthenticatedAppRoutes = React.lazy(() => import("../routes/authenticated-app-routes"));
const UnAuthenticatedAppRoutes = React.lazy(() => import("../routes/unauthenticated-app-routes"));

const Routes = () => {
    const locale = useSelector(({ settings }) => settings.locale);
    const isTokenExist = AuthService.isTokenExist();
    const currentAppLocale = AppLocale.find((x) => x.id === locale);

    return (
        <ConfigProvider locale={currentAppLocale.lang.antdLocale}>
            {isTokenExist ? <AuthenticatedAppRoutes /> : <UnAuthenticatedAppRoutes />}
        </ConfigProvider>
    );
};

export default Routes;
