import React, { memo } from "react";
import { Spin } from "antd";

const FullPageLoader = () => {
    return (
        <React.Fragment>
            <div className="loader loading">
                <Spin /> Please wait ...
            </div>
        </React.Fragment>
    );
};

export default memo(FullPageLoader);
