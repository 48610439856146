import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateAssignee: false
};

export const assignSlice = createSlice({
    name: "assign",
    initialState,
    reducers: {
        resetUpdateAssignee: (state) => {
            state.updateAssignee = initialState.updateAssignee;
        },
        changeUpdateAssignee: (state, action) => {
            state.updateAssignee = action.payload;
        }
    }
});

export const { resetUpdateAssignee, changeUpdateAssignee } = assignSlice.actions;

export default assignSlice.reducer;
