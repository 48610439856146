export const guadians_value = "ADULT";
export const GUARDIAN_ENUM = "GUARDIANSHIP";
export const MINOR = "MINOR";
export const NO = "no";

export const SERVICES = "services";
export const DATA_TYPE = {
    STRING: "string"
};
export const contactDetails = [
    "createdAt",
    "updatedAt",
    "id",
    // "routingNo",
    "guardianExpiry",
    "guardianType",
    "guardianName",
    "guardianAddress",
    "guardianFax",
    "guardianEmail",
    "guardianContact",
    "guardianDocument",
    "services",
    // "sourceOfIncome",
    // "repPayee",
    "contractId"
    // "ssn"
];
export const dataListing = [
    {
        key: "1",
        DocumentName: "IPC",
        Description: "Phillip",
        Status: "Pending"
    },
    {
        key: "2",
        DocumentName: "IPC",
        Description: "Phillip",
        Status: "Pending"
    },
    {
        key: "3",
        DocumentName: "IPC",
        Description: "Phillip",
        Status: "Pending"
    },
    {
        key: "4",
        DocumentName: "IPC",
        Description: "Phillip",
        Status: "Pending"
    },
    {
        key: "5",
        DocumentName: "IPC",
        Description: "Phillip",
        Status: "Pending"
    },
    {
        key: "6",
        DocumentName: "IPC",
        Description: "Phillip",
        Status: "Pending"
    },
    {
        key: "7",
        DocumentName: "IPC",
        Description: "Phillip",
        Status: "Pending"
    },
    {
        key: "8",
        DocumentName: "IPC",
        Description: "Phillip",
        Status: "Pending"
    },
    {
        key: "9",
        DocumentName: "IPC",
        Description: "Phillip",
        Status: "Pending"
    }
];

// attributes?.details?.annualTrainingExpiry && moment(attributes?.details?.annualTrainingExpiry)

export const dateFields = [
    "lastNursingAssessment",
    "ipcEnds",
    "ipcStarts",
    "startDate",
    "idrcEnds",
    "icapEnds",
    "dateOfBirth",
    "insuranceExpiry",
    "nurseLicense",
    "annualTraining",
    "driverLicenseExpiry",
    "annualTrainingExpiry",
    "driversLicenceExpiry",
    "guardianExpiry",
    "alternativeDriverLicenseExpiry",
    "idrcStart",
    "licenseExpiration"
];
export const keyName = {
    alternativeDriverFirstname: "Alternative Driver First Name",
    alternativeDriverLastname: "Alternative Driver Last Name",
    transportation: "Transportation",
    companyPhoneNumber: "Company Phone Number",
    licenseNumber: "License Number",
    licenseExpiration: "License Expiration",
    alternativePhoneNumber: "Alternative Phone Number",
    providerPhoneNumber: "Provider Phone Number",
    contractId: "Contract Id",
    alternativeDriverLicense: "Alternative Driver License",
    alternativeDriverLicenseExpiry: "Alternative Driver LicenseExpiry",
    alternativeInsurance: "Alternative Insurance",
    lastNursingAssessment: "Last Nursing Assessment",
    waiver: "Waiver",
    county: "County",
    careId: "Care Id",
    medicaidId: "Medicaid Id",
    ipcStarts: "Ipc Starts",
    ipcEnds: "Ipc Ends",
    icapEnds: "Icap Ends",
    idrcEnds: "Idrc Ends",
    idrcStart: "Idrc Start",
    lon: "Lon",
    lcn: "Lcn",
    startDate: "Start Date",
    programType: "Program Type",
    annualTraining: "Annual Training",
    contactName: "Contact Name",
    contract: "Contract",
    dateOfBirth: "DOB",
    directDeposit: "Direct Deposit",
    driversLicence: "Drivers Licence",
    ein: "Ein",
    age: "Age",
    componentCode: "Component Code",
    includeReference: "Include Reference",
    insurance: "Vehicle Insurance",
    insuranceExpiry: "Insurance Expiration",
    locationCode: "Location Code",
    medicareId: "Medicare Id",
    nurseLicense: "Nurse License",
    organizationName: "Organization Name",
    organizationType: "Org/Physician Tag",
    // serviceCord: "Service Cord",
    ssn: "SSN",
    workEmail: "Work Email",
    service_name: "Service Name",
    company: "Company",
    companyAddress: "Company Address",
    driversLicenceExpiry: "Driver License Expiration",
    annualTrainingExpiry: "Annual Training Expiration",
    guardianExpiry: "Guardian Expiration",
    guardianType: "Guardian Type",
    guardianName: "Guardian Name",
    guardianAddress: "Guardian Address",
    guardianFax: "Guardian Fax",
    guardianEmail: "Guardian Email",
    guardianContact: "Guardian Contact",
    repPayee: "Rep Payee",
    lidda: "Lidda",
    liddaFirstName: "Lidda First Name",
    liddaEmail: "Lidda Email",
    liddaLastName: "Lidda Last Name",
    liddaPhoneNo: "Lidda Phone No",
    liddaFaxNo: "Lidda Fax No",
    salary: "Salary",
    licenseExpiration: "License Expiration",
    communication: "Communication",
    ambulation: "Ambulation",
    gtube: "G tube",
    insurancePolicyNumber: "Insurance Policy Number",
    faxNo: "Fax No",
    rate: "Rate",
    payRate: "Pay Rate",
    salaryRate: "Salary Rate",
    bankInstitutionName: "Bank Institution Name",
    accountHolderName: "Account Holder Name",
    accountNo: "Account No",
    routingNo: "Routing No",
    sourceOfIncome: "Source Of Income",
    repPayee: "Rep Payee",
    ssn: "Ssn"
};

export const PERGE_VALUE = {
    ACTIVE: "ACTIVE",
    IN_ACTIVE: "IN_ACTIVE"
};

export const CONTACT_PROFILE_TABS_NAME = {
    Notes: "Notes",
    Documents: "Documents",
    UserDocuments: "User Documents",
    UserInvoices: "User Invoices",
    AssignDocumentsSchedule: "Assign Documents Schedule",
    Relationships: "Relationships",
    Charts: "Charts",
    Contracts: "Contracts",
    HR: "HR",
    FrontDeskReceptionist: "Front Desk Receptionist",
    AccountingBilling: "Accounting Billing",
    ProgramManager: "Program Manager",
    Dietician: "Dietician",
    Therapist: "Therapist",
    Dentist: "Dentist",
    ProgramManager: "Program Manager",
    Nurse: "Nurse",
    Referral: "Referral",
    Warnings: "Warnings",
    CareCoordinatorDocumentation: " Care Coordinator Documentation",
    UserDocumentCategory: "User Document Category",
    UserDocumentEmailReview: "User Document Email Review",
    PergeDocument: "Purge Document",
    RepPayee: "Rep Payee",
    Others: "Others",
    ProviderNotes: "Provider Notes",
    SpreadSheet: "Spreadsheet"
};

