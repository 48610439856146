import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import SettingsReducer from "../slices/Settings";
import CommonReducer from "../slices/Common";
import userRelationSlice from "../slices/UserRelation";
import dashboardSlice from "../slices/Dashboard";
import userInfoSlice from "../slices/UserInfo";
import assignSlice from "../slices/Assign";

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        userInfo: userInfoSlice,
        settings: SettingsReducer,
        common: CommonReducer,
        relation: userRelationSlice,
        dashboard: dashboardSlice,
        assign: assignSlice
    });

export default createRootReducer;
