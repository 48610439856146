import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        loading: false,
        data: "",
        isIframeVisible: false
    },
    reducers: {
        fetchStartDashboard: (state) => ({
            ...state,
            loading: true
        }),
        fetchSuccessDashboard: (state) => ({
            ...state,
            loading: false
        }),
        dashboardData: (state, action) => ({
            ...state,
            data: action.payload
        }),
        setIframeVisible: (state, action) => ({
            ...state,
            isIframeVisible: action.payload
        })
    }
});

export const { dashboardData, fetchStartDashboard, fetchSuccessDashboard, setIframeVisible } =
    dashboardSlice.actions;

export default dashboardSlice.reducer;
