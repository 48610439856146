export const APP_NAME = "Visionze";

export const profileLeavingTypes = {
    CREATE_FORM: "createForm"
};

export const STORAGE_CONST_UTIl = {
    USER_INFO: "USER_INFO"
};
export const COMPLETED = "COMPLETED";
export const STATUS_VALUE = {
    1: "Active",
    0: "InActive",
    PENDING: "PENDING",
    OPENED: "OPENED"
};

export const TASK_STATUS = ["OPENED", "COMPLETED", "OVERDUE"];

export const USER_ROLE = {
    NURSE: 10,
    CLIENT_COORDINATOR: 11,
    ORGANIZATION: "organization"
};

export const DOCUMENT_TYPE = {
    IPC: "IPC",
    ANNUAL_PAPERWORK: "APW",
    FIELD_BOOK_FOR_PROVIDER: "FBFP",
    FOSTER_CARE: "FC",
    DAYHAB: "DH",
    PASHAB: "PH",
    RESPITE: "RP",
    IDRC: "IDRC",
    FIRE_DRILL: "FD"
};

export const DOCUMENT_STATUS = {
    COMPLETED_BY_ADMIN: "Completed by Admin",
    COMPLETED: "Completed by user"
};
export const DOCUMENT_CATEGORIES = [
    {
        name: "Others",
        value: "OTHER"
    },
    {
        name: "HR",
        value: "HR"
    }
];

// export const DOCUMENT_CATEGORIES = [
// {
//     name: "IPC",
//     value: "IPC",
// },
// {
//     name: "Annual Paper Work ",
//     value: "APW",
// },
// {
//     name: "Field Book For Provider",
//     value: "FBFP",
// },
// {
//     name: "Foster Care",
//     value: "FC",
// },
// {
//     name: "Dayhab",
//     value: "DH",
// },
// {
//     name: "Pashab",
//     value: "PH",
// },
// {
//     name: "Respite",
//     value: "RP",
// },
// {
//     name: "IDRC",
//     value: "IDRC",
// },
// {
//     name: "Fire Drill",
//     value: "FD",
// },
// ];
// export const DOCUMENT_HR_CATEGORIES = [
// {
//     name: "Provider Responsibility",
//     value: "PROVIDER_RESPONSIBILTY",
// },
// {
//     name: "Competency Test,",
//     value: "COMPETENCY_TEST,",
// },
// {
//     name: "Application For Employement",
//     value: "APPLICATION_FOR_EMPLOYEMENT",
// },
// {
//     name: "Infection Control",
//     value: "INFECTION_CONTROL",
// },
// {
//     name: "Client Base",
//     value: "CLIENT_BASE",
// },
// {
//     name: "Authorization For Employee",
//     value: "AUTHORIZATION_FOR_EMPLOYEE",
// },
// {
//     name: "Dps Authorized",
//     value: "DPS_AUTHORIZED",
// },
// {
//     name: "Medicaid Fraud",
//     value: "MEDICAID_FRAUD",
// },
// {
//     name: "Hippa Law",
//     value: "HIPPA_LAW",
// },
// {
//     name: "Providers Obligation",
//     value: "PROVIDERS_OBLIGATION",
// },
// ];

export const RESPONSE_STATUS = {
    COMPLETED: "COMPLETED"
};

export const DOCUMENT_GROUP = {
    YES: "YES"
};
export const DOCUMENTS_TYPE = {
    HR: "HR",
    NURSE: "NURSE",
    OTHER: "OTHER",
    CHARTS: "CHARTS",
    CONTRACTS: "CONTRACTS",
    RESIDENTIAL_ROOM_AND_BOARD: "residential_room_and_board",
    RESIDENTIAL_ROOM_AND_BOARD_2: "CONTRACTS",
    REFERAL: "REFERRAL",
    CCC: "CCC",
    FRONT_DESK_RECEPTIONIST: "FRONT_DESK_RECEPTIONIST",
    PROGRAM_MANAGER: "PROGRAM_MANAGER",
    ACCOUNTING_BILLING: "ACCOUNTING_BILLING",
    REP_PAYEE: "REP_PAYEE",
    PROVIDER_NOTES: "PROVIDER_NOTES",
    DIETICIAN: "DIETICIAN",
    THERAPIST: "THERAPIST",
    DENTIST: "DENTIST"
};

export const DOCUMENT_CATEGORY_TYPE = {
    HR: "HR",
    PROVIDER_NOTES: "PROVIDER_NOTES",
    CHARTS: "CHARTS",
    NURSE: "NURSE",
    CONTRACTS: "CONTRACTS",
    OTHER: "OTHER",
    REP_PAYEE: "REP_PAYEE"
};

export const DOCUMENT_FILLED = {
    ONLY_ADMIN: "ONLY_ADMIN",
    ONLY_ASSIGNER: "ONLY_ASSIGNER",
    BOTH: "BOTH"
};

export const ASSIGNED_DOCUMENT_STATUS = {
    COMPLETED_BY_ADMIN: "COMPLETED_BY_ADMIN",
    COMPLETED: "COMPLETED"
};

export const DOCUMENT_FILLED_STATUS = {
    ONLY_ADMIN: "ONLY_ADMIN",
    ONLY_ASSIGNER: "ONLY_ASSIGNER",
    BOTH: "BOTH"
};

export const ROLES_NAMES = {
    ADMIN: "Admin",
    FOSTER_CARE_PROVIDER: "Foster Care Provider",
    HR: "HR",
    FRON_DESK_PERSONEL: "Front Desk Personnel",
    STATE_AUDITOR: "State Auditor",
    PROVIDER: "Provider",
    PARENT_LAR: "Parent / LAR",
    ORGANIZATION: "Organizartion",
    PHYSICIAN: "Physician",
    NURSE: "Nurse",
    CLIENT_CARE_COORDINATOR: "Client Care Coordinator",
    CONSUMER: "Consumer",
    MANAGER: "Manager"
};

export const ROLE_TYPES = [
    {
        role_type_name: "User",
        role_type_value: "user"
    },
    {
        role_type_name: "System Coordinator",
        role_type_value: "system-coordinator"
    },
    {
        role_type_name: "Service Provider",
        role_type_value: "service-provider"
    }
];
export const LOCAL_STORAGE_DATA = {
    USER_DOC: "userDoc",
    FILLED_BY_ADMIN: "FilledByAdmin",
    DOCU_SIGN_DATA: "docusignData",
    VARIATION_DATA: "variationData",
    VARIATION_TAB_NAME: "VariationTabName",
    AUTHOR_SIDEDOCUMENT: "AuthorSideDocument",
    ISAUDITOR: "isAuditor",
    CONTACT_PROFILE_LEAVING_DATA: "contactProfileLeavingData",
    QUATERLY_ID: "quaterly_id",
    VIEW_DOCUMENT_ACTION: "VIEW_DOCUMENT_ACTION",
    VARIATION_PAYLOAD: "variation_payload",
    CONTACT_PROFILE_ID_FOR_LEAVING_PROFILE_POPUP: "contactProfileIdForLeavingProfilePopup"
};

export const VIEW_DOCUMENT_ACTIONS = {
    EDIT_URL: "EDIT_URL",
    CREATE_URL: "CREATE_URL"
};
export const COORDINATOR = "system-coordinator";
export const FILLED_BY_ATTRIBUTES = {
    FILLER: "FILLER",
    SENDER: "SENDER",
    VIEWER: "VIEWER"
};

export const FILLER_TABS_NAMES = {
    MAIN: "Main",
    VARIATION: "Variation"
};

// export enum DocumentTwoStepEnum {
// ONE = "ONE",
// TWO = "TWO",
// THREE = "THREE",
// }

export const SHOW_VIEW_AND_DOWNLOAD_BUTTON = {
    DISABLED: "DISABLED",
    ENABLED: "ENABLED",
    HIDDEN: "HIDDEN"
};

export const makeViewRequestCheck = {
    ENABLE: "RecipientViewRequest"
};

export const EDIT_ENVELOPE_LINK = "EditEnvelopeLink";

export const pageSize = 10;

export const PER_PAGE = {
    LIMIT: 100,
    TAKE: 1000
};
export const PROGRAM_TYPE = [
    {
        programe_type_name: "BOTH"
    },
    {
        programe_type_name: "HCS"
    },
    {
        programe_type_name: "TXHML"
    }
];
export const SERVICES = [
    {
        service_name: "CFC"
    },
    {
        service_name: "ISS"
    },
    {
        service_name: "HH"
    },
    {
        service_name: "REH"
    }
];
export const NUMBER_PATTERN = {
    PATTERN: /^[-+]?[0-9]+\.[0-9]+$/
};

export const NUMBER_PATTERN_TWO = {
    PATTERN: /^[-+]?[0-9]+$/
};
export const colors = [
    "#FFE1BF",
    "#FFA1BA",
    "#80D2FF",
    "#82FFB4",
    "#003366",
    "#83a6ed",
    "#8dd1e1",
    "#eeb665",
    "#a4de6c",
    "#d0ed57",
    "#FE9E15",
    "#82FFB6",
    "#346c87"
];
export const STATUS_COLORS = {
    active_color: "#00546F",
    non_active_color: "#F3F3F3"
};
export const CASELOADACTIVITY_COLORS = {
    lineOneColor: "#FFA1BA",
    lineTwoColor: "#80D2FF",
    lineThreeColor: "#82FFB4"
};
export const CONTACTGRAPH_COLORS = {
    stopColorLineOne: "#F1C9CD",
    stopColorLineTwo: "#FEF8F8"
};

export const USER_DOCUMENT_TYPE_FOR_NURSE = [
    {
        name: "CNA BACKGROUND",
        value: "cna_background"
    },
    {
        name: "EXCLUSION FORM",
        value: "exclusion_form"
    },
    {
        name: "SPEACIAL NEEDS TRAINING",
        value: "speacial_needed_training"
    },
    {
        name: "PHYSICIAN ORDERS",
        value: "physical_order"
    },
    {
        name: "MEDIACAL RECORDS",
        value: "medical_records"
    },
    {
        name: "NURSE NOTES",
        value: "nurse_notes"
    },
    {
        name: "RESTRAINT RISK ASSESSMENT",
        value: "restoration_risk_assessment"
    },
    {
        name: "SEIZURE",
        value: "seizure"
    }
];

export const ANALYTICS_TYPE = {
    ADMIN: "ADMIN",
    OTHER: "OTHER"
};

export const USER_DOCUMENT = "Provider Documentation";

export const STATUS_ENVELOPE = {
    UPDATE_ENVELOPE: "UPDATE_ENVELOPE",
    CLONE_ENVELOPE: "CLONE_ENVELOPE"
};

export const QUATERLY_FOLLOW_UP_BUILDER_DOC_LINK =
    "https://stg-signature.visionze.com/template/signing?authorization=VTJGc2RHVmtYMTkyb05TMFNxa21vRFVVd0VjMU9SZVIvQ0Z2Y0xDTGl1MngxV0pEWHdIb1ZlbG9xT0ZsN24yL09KWVBiME1CSkxOdUcxZVZ5cVRtOHc9PQ==";

export const globalDateFormat = "MM-DD-YYYY";

export const AuditorDocumentType = {
    ASSIGNED_DOCUMENT: "assigned_document",
    USER_DOCUMENT: "user_document",
    INVOICE: "invoice",
    REFERRAL: "referral"
};

export const AuditorTabsNames = {
    Charts: "Charts",
    Contracts: "Contracts",
    HR: "HR",
    Nurse: "Nurse",
    RepPayee: "Rep Payee",
    FrontDeskReceptionist: "Front Desk Receptionist",
    AccountingBilling: "Accounting Billing",
    ProgramManager: "Program Manager",
    Referral: "Referral",
    UserDocument: "User Document",
    UserInvoice: "User Invoice",
    Therapist: "Therapist",
    ProviderNotes: "Provider Notes"

    //=================================
    // Notes: "Notes",
    // Documents: "Documents",
    // AssignDocumentsSchedule: "Assign Documents Schedule",
    // Relationships: "Relationships",
    // Dietician: "Dietician",
    // Dentist: "Dentist",
    // Warnings: "Warnings",
    // CareCoordinatorDocumentation: " Care Coordinator Documentation",
    // UserDocumentCategory: "User Document Category",
    // UserDocumentEmailReview: "User Document Email Review",
    // PergeDocument: "Purge Document",
    // Others: "Others",
    // SpreadSheet: "Spreadsheet"
    //=================================
};

export const AuditorUserTabsNames = {
    UserDocument: "User Document",
    FormDocuments: "Form Documents",
    UserInvoice: "User Invoice",
    Referral: "Referral"
};

export const searchDebounceTime = 500;

export const logoutResetEvent = ["mousedown", "mousemove", "keydown", "scroll", "touchstart", "touchmove"];

