import moment from "moment";
import React, { useEffect, useState } from "react";
import packageJson from "../package.json";
import dateTimeService from "./util/date-time.service";
import LocalStorageService from "./util/local-storage.service";
// import dateTimeService from "cargobarn-core/util/date-time.service";

const buildDateGreaterThan = (latestDate, currentDate) => {
    if (dateTimeService.isAfter(latestDate, currentDate)) {
        return true;
    } else {
        return false;
    }
};

function withClearCache(Component) {
    function ClearCacheComponent(props) {
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);
        const checkDate = LocalStorageService.get("buildDate");
        useEffect(() => {
            fetch("/meta.json")
                .then((response) => response.json())
                .then((meta) => {
                    const latestVersionDate = meta.buildDate;
                    const currentVersionDate = packageJson.buildDate;
                    // const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);

                    if (latestVersionDate != checkDate) {
                        LocalStorageService.set("buildDate", latestVersionDate);
                        setIsLatestBuildDate(false);
                        setTimeout(() => {
                            refreshCacheAndReload();
                        }, 500);
                    } else {
                        setIsLatestBuildDate(true);
                    }
                });
        }, []);

        const refreshCacheAndReload = () => {
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                });
            }
            // delete browser cache and hard reload
            window.location.reload(true);
        };

        return <React.Fragment>{isLatestBuildDate ? <Component {...props} /> : null}</React.Fragment>;
    }

    return ClearCacheComponent;
}

export default withClearCache;

